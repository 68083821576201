<template>
  <el-dialog
    draggable
    v-model="EditEmailShow"
    :modal="false"
    top="20vh"
    :title="t('Update Email')"
    class="editEmailDialog"
    destroy-on-close
    append-to-body
    @close="closeEditEmailShow"
  >
    <el-form
      label-position="left"
      inline
      :model="editUserEmil"
      :rules="editEmailRules"
      ref="editEmailRef"
    >
      <el-form-item prop="newEmail">
        <template #label>
          <span class="custom-label" v-textTootip:auto>{{
            t("New Email")
          }}</span
          ><span class="required" style="color: #ff0000">*</span>
        </template>
        <el-input v-model="editUserEmil.newEmail" />
      </el-form-item>
      <el-form-item prop="emailCode">
        <template #label>
          <span class="custom-label" v-textTootip:auto>{{
            t("Registration Code")
          }}</span
          ><span class="required" style="color: #ff0000">*</span>
        </template>
        <el-input v-model="editUserEmil.emailCode"> </el-input>
      </el-form-item>
      <el-form-item>
        <div
          class="flexMiddle"
          style="width: 100%; height: 100%; cursor: pointer"
        >
          <span
            v-show="EditEmailCodeState == 0"
            style="color: #6f9c29"
            v-debounce:click="{ handler: EditEmailCode, delay: 300 }"
            >{{ t("Get Code") }}</span
          >
          <span v-show="EditEmailCodeState == 1" style="color: #6f9c29"
            >{{ t("Resend") }}({{ Countdown }})s</span
          >
          <span
            v-show="EditEmailCodeState == 2"
            style="color: #6f9c29"
            v-debounce:click="{ handler: EditEmailCode, delay: 300 }"
            >{{ t("Resend") }}</span
          >
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-row type="flex" align="middle" justify="center">
        <el-button
          type="primary"
          v-debounce:click="{ handler: editEmailSubmit, delay: 300 }"
          >{{ t("Confirm") }}</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
      
<script setup>
import { watch, ref } from "vue";
const { t } = useI18n();
const props = defineProps({
  EditEmailInnerShow: {
    type: Boolean,
    default: false,
  },
});
let EditEmailShow = ref(false);
watch(
  () => props.EditEmailInnerShow,
  (value) => {
    EditEmailShow.value = value;
  }
);

let editUserEmil = reactive({
  newEmail: "",
  emailCode: "",
});
let emit = defineEmits(['closeEditEmailShow',"getUserInfo"])
function closeEditEmailShow() {
  emit("closeEditEmailShow")
}

let editEmailRef=ref(null)
let Countdown=ref(0)
let EditEmailCodeState=ref(0)
let timer=null
async function EditEmailCode() {
  editEmailRef.value.validateField("newEmail", async (valid) => {
    console.log(valid);
    if (valid) {
      let res = await postRequest("/dental/base/sendActivationEmail", {
        Email: editUserEmil.newEmail,
      });
      if (res.code != 200) {
        ElMessage({
          message: res.message,
          type: "error",
        });
        EditEmailCodeState.value = 2; // 倒计时结束，显示“重发注册码”
        return;
      }

      if (EditEmailCodeState.value === 0 || EditEmailCodeState.value === 2) {
        EditEmailCodeState.value = 1; // 开始倒计时
        Countdown.value = 60;

        timer = setInterval(() => {
          if (Countdown.value > 0) {
            Countdown.value--;
          } else {
            clearInterval(timer);
            EditEmailCodeState.value = 2; // 倒计时结束，显示“重发注册码”
          }
        }, 1000);
      }
      ElMessage({
        message: res.message,
        type: "success",
      });
    }
  });
}

async function editEmailSubmit() {
  editEmailRef.value.validate(async (valid) => {
    if (valid) {
      let res = await postRequest("/dental/user/editEmail", {
        EmailActivationCode: editUserEmil.emailCode,
        Email: editUserEmil.newEmail,
      });
      if (res.code != 200) {
        ElMessage.error(res.message);
        return;
      }
      ElMessage.success(res.message);
      emit("closeEditEmailShow")
      emit('getUserInfo')
      ;
    }
  });
}
const verifyEmail = (rule, value, callback) => {
  if (value == "") {
    callback(new Error(t("Please enter your email address")));
  } else {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      callback(new Error(t("Please input a valid email address")));
    }
    callback();
  }
};
const verifyregisterCode = (rule, value, callback) => {
  if (value == "") {
    callback(new Error(t("Please enter the registration code")));
  } else {
    callback();
  }
};
const editEmailRules = reactive({
  newEmail: [{ validator: verifyEmail, trigger: "blur" }],
  emailCode: [{ validator: verifyregisterCode, trigger: "blur" }],
});
</script>
      
  <style lang='scss'>
  // 修改邮箱dialog
  .editEmailDialog {
  width: 640px;
  padding: 0;
  border: 1px solid #ccd0d5;
  border-radius: 8px;
  box-shadow: 4px 4px 16px 0px #13141540;
  .el-dialog__header {
    margin-right: 0px;
    height: 64px;
    padding: 0px;
    border-bottom: 1px solid #ccd0d5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    .el-dialog__title {
      margin-left: 64px;
      font-size: 20px;
      font-weight: 700;
    }
    .el-dialog__headerbtn {
      position: static !important; /* 强制取消绝对定位 */
      margin-top: 12px;
      margin-right: 40px;
      color: #000;
      background: url("/public/image/home/accountClose.png");
      background-size: 40px;
      i {
        display: none; // 在这里隐藏弹窗默认的关闭图标
      }
    }
  }
  .el-form-item {
    width: 408px;
    margin-right: 8px;
    .el-input__inner {
      height: 40px;
    }
    .el-form-item__label {
      width: 108px;
      height: 40px;
      line-height: 40px;
    }
  }
  .el-form-item:nth-of-type(3) {
    width: 120px;
    margin-right: 8px;
    margin-right: 0;
    .flexMiddle {
      text-align: center;
    }
  }
  .el-dialog__body {
    padding: 48px 0 0 64px;
  }
  .el-dialog__footer {
    height: 136px;
    .el-button {
      width: 200px;
      height: 48px;
    }
  }
}
</style>
  