/*
 * @Description: 
 * @Autor: fzw
 * @Date: 2024-07-18 10:59:24
 * @LastEditTime: 2024-09-03 11:25:38
 */
import axios from "@/tools/axios/index"
import { PublicStore } from "@/tools/pinia/store";
import  { VueI18n } from "@/tools/i18n";
function getRequest(url, data) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: data
        }).then((response) => {
            resolve(response.data)
        }).catch((response) => {
            resolve(response)
        })
    })
}
function postRequest(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then((response) => {
            resolve(response.data)
        }).catch((response) => {
            resolve(response)
        })
    })
}
function postRequestFile(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            timeout: 0
        }).then((response) => {
            resolve(response.data)
        }).catch((response) => {
            resolve(response)
        })
    })
}

const progressTracker = {
    totalLoaded: 0,
    totalSize: 0,
    requests: new Map()
};
let urlArr = [];
function loadModel(url, data) {
    urlArr.push(url)
    return new Promise(async (resolve, reject) => {
        let publicData = PublicStore()
        publicData.changeLoadingShow(true)
        let takeUrl = urlArr.shift()
        let modelData = await getItem(takeUrl)
        if (modelData) {
            resolve(modelData);
            if (urlArr.length == 0) {
                // publicData.changeLoadingShow(false)
            }
            return
        }

        axios.get(takeUrl, {
            params: data,
            responseType: 'arraybuffer',
            timeout: 0,
            onDownloadProgress: (progressEvent) => {
                if (progressEvent.lengthComputable) {
                    publicData.changeLoadingShow(true)
                    // 获取当前请求的上一次加载状态
                    const previousLoaded = progressTracker.requests.get(url)?.loaded || 0;

                    // 更新当前请求的进度信息
                    progressTracker.requests.set(url, {
                        loaded: progressEvent.loaded,
                        total: progressEvent.total
                    });

                    // 更新全局进度状态
                    progressTracker.totalLoaded += (progressEvent.loaded - previousLoaded);

                    // 如果这是第一次更新该请求，则增加总大小
                    if (previousLoaded === 0) {
                        progressTracker.totalSize += progressEvent.total;
                    }

                    // 计算总体加载进度
                    const totalProgress = Math.round((progressTracker.totalLoaded * 100) / progressTracker.totalSize);
                    // if (totalProgress == 100&&urlArr.length == 0) publicData.changeLoadingShow(false);
                    publicData.changeLoadingText(`${VueI18n.global.t('Loading')}${totalProgress}%`)
                } 
            }
        }).then((response) => {
            setItem(url, response.data)
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}



function apiGetImg(url, data) {
    return new Promise((resolve, reject) => {
        axios({
            url: url,
            method: 'get',
            // responseType: 'blob',
            params: data
        }).then(res => {
            resolve(res.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export { getRequest, postRequest, apiGetImg, postRequestFile, loadModel }