<template>
  <el-dialog
    draggable
    v-model="EditPasswordShow"
    :modal="false"
    top="20vh"
    :title="t('Update Password')"
    class="editPasswordDialog"
    destroy-on-close
    append-to-body
    @close="closeEditUserPasswordShow"
  >
    <el-form
        label-position="left"
        :model="editUserPassword"
        :rules="editUserPasswordRules"
        ref="editUserPasswordRef"
      >
        <el-form-item prop="oldPassword">
          <template #label>
            <span class="custom-label" v-textTootip:auto>{{
              $t("Old Password")
            }}</span
            ><span class="required" style="color: #ff0000">*</span>
          </template>
          <el-input v-model="editUserPassword.oldPassword" />
        </el-form-item>
        <el-form-item prop="newPassword">
          <template #label>
            <span class="custom-label" v-textTootip:auto>{{
              $t("New Password")
            }}</span
            ><span class="required" style="color: #ff0000">*</span>
          </template>
          <el-input v-model="editUserPassword.newPassword" />
        </el-form-item>
        <el-form-item prop="confirmPassword">
          <template #label>
            <span class="custom-label" v-textTootip:auto>{{
              $t("Confirm Password")
            }}</span
            ><span class="required" style="color: #ff0000">*</span>
          </template>
          <el-input v-model="editUserPassword.confirmPassword" />
        </el-form-item>
      </el-form>
    <template #footer>
        <el-row type="flex" align="middle" justify="center">
          <el-button
            type="primary"
            v-debounce:click="{ handler: editPasswordSubmit, delay: 300 }"
            >{{ t("Confirm") }}</el-button
          >
        </el-row>
      </template>
  </el-dialog>
</template>
      
<script setup>
const { t } = useI18n();
const props = defineProps({
  EditPasswordInnerShow: {
    type: Boolean,
    default: false,
  },
});
let EditPasswordShow = ref(false);
watch(
  () => props.EditPasswordInnerShow,
  (value) => {
    EditPasswordShow.value = value;
  }
);
let editUserPassword = reactive({
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
});
let editUserPasswordRef = ref(null);
let emit = defineEmits(["closeEditUserPasswordShow","getUserInfo"]);
function closeEditUserPasswordShow() {
  emit("closeEditUserPasswordShow");
}

function editPasswordSubmit() {
  editUserPasswordRef.value.validate(async (valid) => {
    if (valid) {
      let res = await postRequest("/dental/user/editPassword", {
        OldPassword: editUserPassword.oldPassword,
        NewPassword: editUserPassword.newPassword,
      });
      if (res.code != 200) {
        ElMessage.error(res.message);
        return;
      }

      ElMessage.success(res.message);
      emit("closeEditUserPasswordShow");
      emit("getUserInfo");
    }
  });
  return;
}


const verifyPass = (rule, value, callback) => {
  if (value === "") {
    callback(new Error(t("Please enter password")));
  } else {
    callback();
  }
};
const verifyConfirmPass = (rule, value, callback) => {
  if (value == "") {
    callback(new Error(t("Please enter your password here")));
  } else if (value !== editUserPassword.newPassword) {
    callback(new Error(t("The two passwords are inconsistent")));
  } else {
    callback();
  }
};
const editUserPasswordRules = reactive({
  oldPassword: [{ validator: verifyPass, trigger: "blur" }],
  newPassword: [{ validator: verifyPass, trigger: "blur" }],
  confirmPassword: [{ validator: verifyConfirmPass, trigger: "blur" }],
});
</script>
      
<style lang='scss'>
  // 修改密码dialog
.editPasswordDialog {
  width: 640px;
  padding: 0;
  border-radius: 8px;
  border: 1px solid #ccd0d5;
  box-shadow: 4px 4px 16px 0px #13141540;
  .el-dialog__header {
    margin-right: 0px;
    height: 64px;
    padding: 0px;
    border-bottom: 1px solid #ccd0d5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    .el-dialog__title {
      margin-left: 64px;
      font-size: 20px;
      font-weight: 700;
    }
    .el-dialog__headerbtn {
      position: static !important; /* 强制取消绝对定位 */
      margin-top: 12px;
      margin-right: 40px;
      color: #000;
      background: url("/public/image/home/accountClose.png");
      background-size: 40px;
      i {
        display: none; // 在这里隐藏弹窗默认的关闭图标
      }
    }
  }
  .el-form-item {
    margin-right: 0px;
    margin-bottom: 20px;
    .el-input__inner {
      height: 40px;
    }
    .el-form-item__label {
      width: 108px;
      height: 40px;
      line-height: 40px;
    }
  }
  .el-dialog__body {
    padding: 48px 64px 0 64px;
  }
  .el-dialog__footer {
    height: 136px;
    .el-button {
      width: 200px;
      height: 48px;
    }
  }
}
</style>
  