/*
 * @Description: 
 * @Autor: fzw
 * @Date: 2024-07-17 18:30:52
 * @LastEditTime: 2024-09-20 14:08:26
 */
import { defineStore } from 'pinia'
import WebSocketService from '@/utils/websocket'
import { useWindowScroll } from '@vueuse/core'
export const PublicStore = defineStore('Public', { // Public项目唯一id
    state: () => {
        return {
            language: 0,//语言
            AccessToken: "",//入口密匙
            RefreshToken: "",//刷新密匙
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,//时区
            UserInfo: {},//当前登录用户信息
            loadingShow: false,//加载动画控制
            loadingText: "",//加载动画提示内容
            WebSocketService: null,//websocket
        }
    },
    getters: {
        getLanguage: (state) => {
            return state.language
        },
        getAccessToken: (state) => {
            return state.AccessToken
        },
        getRefreshToken: (state) => {
            return state.RefreshToken
        },
        getUserInfo: (state) => {
            return state.UserInfo
        },
    },
    actions: {
        changeLanguage(num) { // 不能用箭头函数
            this.language = num
        },
        changeAccessToken(value) { // 不能用箭头函数
            this.AccessToken = value
        },
        changeRefreshToken(value) { // 不能用箭头函数
            this.RefreshToken = value
        },
        changeUserInfo(value) { // 不能用箭头函数
            this.UserInfo = value
        },
        changeLoadingShow(loadingShow) { // 不能用箭头函数
            this.loadingShow = loadingShow
        },
        changeLoadingText(loadingText) { // 不能用箭头函数
            this.loadingText = loadingText
        },
        changeWebSocketService() { // 不能用箭头函数
            let src = window.location.host + "/dental/ws/conn"
            if (window.location.protocol == "https:") {
                src = "wss://" + src
            } else {
                src = "ws://" + src
            }
            this.WebSocketService = new WebSocketService(src, this.AccessToken, this.timeZone)
        },
    },
    persist: {
        enabled: true, // 启用持久化
        key: 'Public', // 存储在 localStorage 中的 key
        storage: localStorage, // 存储方式
        paths: ['language', 'AccessToken', 'RefreshToken', 'UserInfo'], // 仅持久化字段
    },
})
export const loginStore = defineStore('login', {
    state: () => {
        return {
            account: "",
            password: "",
            savePasswordChecked: false,

        }
    },
    getters: {
        getAccount: (state) => {
            return state.account
        },
        getPassword: (state) => {
            return state.password
        },
        getSavePasswordChecked: (state) => {
            return state.savePasswordChecked
        },

    },
    actions: {
        changeAccount(value) { // 不能用箭头函数
            this.account = value
        },
        changePassword(value) { // 不能用箭头函数
            this.password = value
        },
        changeSavePasswordChecked(value) {
            this.savePasswordChecked = value
        },
    },
    persist: {
        enabled: true, // 启用持久化
        key: 'login', // 存储在 localStorage 中的 key
        storage: localStorage, // 使用 localStorage
        paths: ['account', 'password', 'savePasswordChecked'], // 仅持久化 字段
    },
})
export const homeStore = defineStore('login', {
    state: () => {
        return {
            account: "",
            password: "",
            savePasswordChecked: false,

        }
    },
    getters: {
        getAccount: (state) => {
            return state.account
        },
        getPassword: (state) => {
            return state.password
        },
        getSavePasswordChecked: (state) => {
            return state.savePasswordChecked
        },

    },
    actions: {
        changeAccount(value) { // 不能用箭头函数
            this.account = value
        },
        changePassword(value) { // 不能用箭头函数
            this.password = value
        },
        changeSavePasswordChecked(value) {
            this.savePasswordChecked = value
        },
    },
    persist: {
        enabled: true, // 启用持久化
        key: 'home', // 存储在 localStorage 中的 key
        storage: localStorage, // 使用 localStorage
        paths: ['stlCache',], // 仅持久化 字段
    },
})








