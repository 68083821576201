<template>
  <div class="toolContain" style="position: absolute; top: 0; right: 0">
    <tipIcom
      v-if="locale == 'en'"
      iconClass="icon40"
      url="/image/home/Chinese.png"
      :tip="t('Language Switch')"
      @click="languageSwitch"
    ></tipIcom>
    <tipIcom
      v-if="locale == 'zh'"
      iconClass="icon40"
      url="/image/home/English.png"
      :tip="t('Language Switch')"
      @click="languageSwitch"
    ></tipIcom>
    <tipIcom
      iconClass="icon40"
      url="/image/home/assist.png"
      :tip="t('Help')"
    ></tipIcom>
    <tipIcom
      iconClass="icon40"
      url="/image/home/about.png"
      :tip="t('About')"
    ></tipIcom>
  </div>
  <div class="login">
    <div class="title">
      {{ $t("welcome") }}<br /><el-text class="mx-1" type="primary"
        >DentalCloud</el-text
      >
    </div>
    <el-tabs v-model="activeName" class="login-tabs" @tab-change="changeTabs">
      <!-- 登录 -->
      <el-tab-pane :label="$t('login')" name="login" lazy>
        <el-form
          class="loginForm"
          label-position="right"
          label-width="auto"
          :model="loginForm"
          ref="loginFormRef"
          :rules="loginrules"
        >
          <el-form-item label-position="right" prop="account">
            <el-input
              v-debounce:keyup.enter="{ handler: login, delay: 300 }"
              v-model="loginForm.account"
              :placeholder="$t('Please enter your account number')"
            >
              <template #prefix>
                <img
                  class="userIcon"
                  src="/image/login/user.png"
                  alt="Custom Icon"
                />
              </template>
            </el-input>
          </el-form-item>

          <el-form-item label-position="right" prop="password">
            <el-input
              v-debounce:keyup.enter="{ handler: login, delay: 300 }"
              v-model="loginForm.password"
              :type="loginShowPassword ? 'text' : 'password'"
              :placeholder="$t('Please enter password')"
            >
              <template #prefix>
                <img class="lockIcon" src="/image/login/lock-on.png" />
              </template>
              <template #suffix>
                <img
                  class="eyeOff"
                  v-show="!loginShowPassword"
                  style="cursor: pointer"
                  src="/image/login/eye-off.png"
                  @click="togglePasswordVisibility"
                />
                <img
                  class="eyeOn"
                  v-show="loginShowPassword"
                  style="cursor: pointer"
                  src="/image/login/eye-on.png"
                  @click="togglePasswordVisibility"
                />
              </template>
            </el-input>
          </el-form-item>

          <el-form-item label-position="right">
            <div
              style="
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-item: center;
              "
            >
              <el-checkbox
                v-model="savePasswordChecked"
                @change="savePassword"
                >{{ $t("Remember Password") }}</el-checkbox
              >
              <el-button
                link
                type="primary"
                @click="ForgotPasswordDialogShow = !ForgotPasswordDialogShow"
                style="float: right"
              >
                {{ $t("Forgot password") }}</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label-position="right">
            <el-button
              v-debounce:click="{ handler: login, delay: 300 }"
              class="loginBtn"
              type="primary"
              size="large"
              >{{ $t("login") }}</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <!-- 注册 -->
      <el-tab-pane :label="$t('Register New Account')" name="register" lazy>
        <el-form
          class="registerForm"
          ref="registerFormRef"
          label-position="left"
          :model="registerForm"
          :rules="rules"
        >
          <el-form-item label-position="left">
            <template #label>
              <span class="custom-label" v-textTootip:auto>{{
                $t("Account type")
              }}</span>
              <!-- <span class="required" style="color: #ff0000">*</span> -->
            </template>
            <el-select
              popper-class="accountTypeSelect"
              :teleported="false"
              v-model="registerForm.accountType"
              :placeholder="$t('Please select an account type')"
            >
              <el-option :label="$t('Doctor')" value="0" />
              <el-option :label="$t('laboratory')" value="1" />
            </el-select>
          </el-form-item>

          <el-form-item label-position="left" prop="email">
            <template #label>
              <span class="custom-label" v-textTootip:auto>{{
                $t("Email")
              }}</span>
              <span class="required" style="color: #ff0000">*</span>
            </template>
            <el-input
              v-model="registerForm.email"
              v-debounce:keyup.enter="{ handler: register, delay: 300 }"
              :placeholder="$t('Please enter email')"
            >
            </el-input>
          </el-form-item>

          <el-form-item label-position="left">
            <template #label>
              <span class="custom-label" v-textTootip:auto>
                {{ t("Contact Number") }}</span
              >
            </template>
            <el-input
              v-debounce:keyup.enter="{ handler: register, delay: 300 }"
              v-model="registerForm.phone"
              :placeholder="$t('Please Enter Phone Number')"
            >
            </el-input>
          </el-form-item>

          <el-form-item label-position="left" prop="password">
            <template #label>
              <span class="custom-label" v-textTootip:auto>{{
                $t("Password")
              }}</span>
              <span class="required" style="color: #ff0000">*</span>
            </template>

            <el-input
              v-debounce:keyup.enter="{ handler: register, delay: 300 }"
              :type="registerShowPassword ? 'text' : 'password'"
              v-model="registerForm.password"
              :placeholder="$t('Please enter password')"
            >
              <template #suffix>
                <img
                  class="eyeOff"
                  v-show="!registerShowPassword"
                  style="cursor: pointer"
                  src="/image/login/eye-off.png"
                  @click="toggleRegisterPasswordVisibility"
                />
                <img
                  class="eyeOn"
                  v-show="registerShowPassword"
                  style="cursor: pointer"
                  src="/image/login/eye-on.png"
                  @click="toggleRegisterPasswordVisibility"
                />
              </template>
            </el-input>
          </el-form-item>

          <el-form-item
            label-position="left"
            :label="$t('Confirm Password')"
            prop="confirmPassword"
          >
            <template #label>
              <span class="custom-label" v-textTootip:auto>{{
                $t("Confirm Password")
              }}</span
              ><span class="required" style="color: #ff0000">*</span>
            </template>
            <el-input
              v-debounce:keyup.enter="{ handler: register, delay: 300 }"
              :type="registerShowConfirmPassword ? 'text' : 'password'"
              v-model="registerForm.confirmPassword"
              :placeholder="$t('Please enter your confirmation password')"
            >
              <template #suffix>
                <img
                  class="eyeOff"
                  v-show="!registerShowConfirmPassword"
                  style="cursor: pointer"
                  src="/image/login/eye-off.png"
                  @click="toggleRegisterConfirmPasswordVisibility"
                />
                <img
                  class="eyeOn"
                  v-show="registerShowConfirmPassword"
                  style="cursor: pointer"
                  src="/image/login/eye-on.png"
                  @click="toggleRegisterConfirmPasswordVisibility"
                />
              </template>
            </el-input>
          </el-form-item>

          <el-form-item
            label-position="left"
            :label="$t('User Name')"
            prop="username"
          >
            <template #label>
              <span class="custom-label" v-textTootip:auto>{{
                $t("User Name")
              }}</span
              ><span class="required" style="color: #ff0000">*</span>
            </template>
            <el-input
              v-debounce:keyup.enter="{ handler: register, delay: 300 }"
              v-model="registerForm.username"
              :placeholder="$t('Please enter your username')"
            >
            </el-input>
          </el-form-item>

          <el-form-item
            v-show="registerForm.accountType == 1"
            label-position="left"
            prop="clinicName"
          >
            <template #label>
              <span class="custom-label" v-textTootip:auto>{{
                $t("laboratory Name")
              }}</span
              ><span class="required" style="color: #ff0000">*</span>
            </template>
            <el-input
              v-debounce:keyup.enter="{ handler: register, delay: 300 }"
              v-model="registerForm.clinicName"
              :placeholder="$t('Please enter the clinic name')"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            v-show="registerForm.accountType == 0"
            label-position="left"
            prop="clinicName"
          >
            <template #label>
              <span class="custom-label" v-textTootip:auto>{{
                $t("Clinic name")
              }}</span
              ><span class="required" style="color: #ff0000">*</span>
            </template>
            <el-input
              v-debounce:keyup.enter="{ handler: register, delay: 300 }"
              v-model="registerForm.clinicName"
              :placeholder="$t('Please enter the clinic name')"
            >
            </el-input>
          </el-form-item>

          <el-form-item label-position="left">
            <template #label>
              <span class="custom-label" v-textTootip:auto>
                {{ $t("address") }}</span
              >
            </template>
            <el-input
              v-debounce:keyup.enter="{ handler: register, delay: 300 }"
              v-model="registerForm.address"
              :placeholder="$t('Please enter the address')"
            >
            </el-input>
          </el-form-item>

          <el-form-item
            v-if="verificationCodeShow"
            label-position="left"
            prop="verificationCode"
          >
            <template #label>
              <span class="custom-label" v-textTootip:auto>
                {{ $t("Verification code") }} </span
              ><span class="required" style="color: #ff0000">*</span>
            </template>
            <el-input
              class="verificationCodeInput"
              v-debounce:keyup.enter="{ handler: register, delay: 300 }"
              v-model="registerForm.verificationCode"
              :placeholder="$t('Please enter the verification code')"
            >
            </el-input>
            <img
              class="verificationCodeImage"
              :src="VerifyPicture.imageBase64"
            />
            <img
              class="verificationCodeRefresh"
              @click="getVerifyCode"
              src="/image/home/refresh.png"
            />
          </el-form-item>

          <el-form-item label-position="left" prop="registerCode">
            <template #label>
              <span class="custom-label" v-textTootip:auto>
                {{ $t("Registration Code") }} </span
              ><span class="required" style="color: #ff0000">*</span>
            </template>
            <el-input
              class="registerCodeInput"
              v-debounce:keyup.enter="{ handler: register, delay: 300 }"
              v-model="registerForm.registerCode"
              :placeholder="$t('Please enter the registration code')"
            >
            </el-input>
            <div class="getRegisterCode flexMiddle">
              <span
                v-show="registerCodeState == 0"
                style="color: #6f9c29"
                @click="getRegisterCode"
                >{{ t("Get Code") }}</span
              >
              <span v-show="registerCodeState == 1" style="color: #6f9c29"
                >{{ t("Resend") }}({{ Countdown }})s</span
              >
              <span
                v-show="registerCodeState == 2"
                style="color: #6f9c29"
                @click="getRegisterCode"
                >{{ t("Resend") }}</span
              >
            </div>
          </el-form-item>

          <el-button
            class="registerBtn"
            type="primary"
            size="large"
            v-debounce:click="{ handler: register, delay: 300 }"
            >{{ $t("register") }}</el-button
          >
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
  <!-- 忘记密码 -->
  <el-dialog
    draggable
    class="ForgotPasswordDialog"
    destroy-on-close
    v-model="ForgotPasswordDialogShow"
    :title="t('Recover Password')"
    :modal="false"
    :close-on-click-modal="false"
  >
    <div class="ForgotPasswordContent">
      <el-form
        class="ForgotPasswordForm"
        ref="ForgotPasswordRef"
        label-position="left"
        :model="ForgotPasswordForm"
        :rules="ForgotPasswordRules"
      >
        <el-form-item prop="email">
          <template #label>
            <span class="custom-label" v-textTootip:auto>{{ $t("Email") }}</span
            ><span class="required" style="color: #ff0000">*</span>
          </template>
          <el-input
            v-debounce:keyup.enter="{
              handler: ForgotPasswordSubmit,
              delay: 300,
            }"
            v-model="ForgotPasswordForm.email"
            :placeholder="$t('Please enter email')"
          >
          </el-input>
        </el-form-item>

        <el-form-item prop="registerCode">
          <template #label>
            <span class="custom-label" v-textTootip:auto>
              {{ $t("Registration Code") }} </span
            ><span class="required" style="color: #ff0000">*</span>
          </template>
          <el-input
            class="registerCodeInput"
            v-debounce:keyup.enter="{
              handler: ForgotPasswordSubmit,
              delay: 300,
            }"
            v-model="ForgotPasswordForm.registerCode"
            :placeholder="$t('Please enter the registration code')"
          >
          </el-input>
          <div class="ForgotPasswordCode flexMiddle">
            <span
              v-show="ForgotPasswordCodeState == 0"
              style="color: #6f9c29"
              @click="getForgotPasswordCode"
              >{{ t("Get Code") }}</span
            >
            <span v-show="ForgotPasswordCodeState == 1" style="color: #6f9c29"
              >{{ t("Resend") }}({{ Countdown }})s</span
            >
            <span
              v-show="ForgotPasswordCodeState == 2"
              style="color: #6f9c29"
              @click="getForgotPasswordCode"
              >{{ t("Resend") }}</span
            >
          </div>
        </el-form-item>

        <el-form-item prop="password">
          <template #label>
            <span class="custom-label" v-textTootip:auto>{{
              $t("Password")
            }}</span
            ><span class="required" style="color: #ff0000">*</span>
          </template>
          <el-input
            v-debounce:keyup.enter="{
              handler: ForgotPasswordSubmit,
              delay: 300,
            }"
            v-model="ForgotPasswordForm.password"
            :placeholder="$t('Please enter password')"
          >
          </el-input>
        </el-form-item>

        <el-form-item prop="confirmPassword">
          <template #label>
            <span class="custom-label" v-textTootip:auto>{{
              $t("Confirm Password")
            }}</span
            ><span class="required" style="color: #ff0000">*</span>
          </template>
          <el-input
            v-debounce:keyup.enter="{
              handler: ForgotPasswordSubmit,
              delay: 300,
            }"
            v-model="ForgotPasswordForm.confirmPassword"
            :placeholder="$t('Please enter your password here')"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-row type="flex" align="middle" justify="center">
        <el-button
          class="ForgotPasswordBtn"
          type="primary"
          v-debounce:click="{ handler: ForgotPasswordSubmit, delay: 300 }"
          >{{ t("Confirm") }}</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>

<script setup>
import { loginStore, PublicStore } from "@/tools/pinia/store.js";
import instance from "@/tools/axios/index.js";
import { reactive } from "vue";
import WebSocketService from "@/utils/websocket.js";
let loginStores = loginStore();
let PublicStores = PublicStore();
const { t, locale } = useI18n();
// 语言切换
function languageSwitch() {
  if (locale.value == "zh") {
    locale.value = "en";
    PublicStores.changeLanguage(0);
  } else {
    locale.value = "zh";
    PublicStores.changeLanguage(1);
  }
}
// tab选中
const activeName = ref("login");
let VerifyPicture = reactive({});
async function changeTabs(value) {
  if (value == "register") {
    getVerifyCode();
  }
}
// 登录密码显示隐藏操作
const loginShowPassword = ref(false);
const togglePasswordVisibility = () => {
  loginShowPassword.value = !loginShowPassword.value;
};
// 注册密码显示隐藏操作
const registerShowPassword = ref(false);
const registerShowConfirmPassword = ref(false);
const toggleRegisterPasswordVisibility = () => {
  registerShowPassword.value = !registerShowPassword.value;
};
const toggleRegisterConfirmPasswordVisibility = () => {
  registerShowConfirmPassword.value = !registerShowConfirmPassword.value;
};
// 获得验证码
async function getVerifyCode() {
  let res = await getRequest("/dental/base/getCaptcha");
  if (res.code == 200) {
    VerifyPicture.ID = res.data.ID;
    VerifyPicture.imageBase64 = res.data.Image;
  }
}
// 登录表单信息
let loginFormRef = ref(null);
const loginForm = reactive({
  account: loginStores.account || "",
  password: loginStores.password || "",
});
const verifyAccount = (rule, value, callback) => {
  if (value == "") {
    callback(new Error(t("The account cannot be empty")));
  } else {
    callback();
  }
};
const verifyPassword = (rule, value, callback) => {
  if (value == "") {
    callback(new Error(t("The password cannot be empty")));
  } else {
    callback();
  }
};
const loginrules = reactive({
  account: [{ validator: verifyAccount, trigger: "blur" }],
  password: [{ validator: verifyPassword, trigger: "blur" }],
});

// 密码保存勾选
let savePasswordChecked = ref(loginStores.getSavePasswordChecked);
function savePassword(value) {
  if (value) {
    loginStores.changeAccount(loginForm.account);
    loginStores.changePassword(loginForm.password);
  } else {
    loginStores.changeAccount("");
    loginStores.changePassword("");
  }
  loginStores.changeSavePasswordChecked(value);
}

const router = useRouter();
async function login() {
  loginFormRef.value.validate(async (valid) => {
    if (!valid) {
      return;
    }
    let data = {
      Email: loginForm.account,
      Password: loginForm.password,
    };
    let res = await postRequest("/dental/base/login", data);
    if (res.code == 200) {
      PublicStores.changeAccessToken(res.data.AccessToken);
      PublicStores.changeRefreshToken(res.data.RefreshToken);
      PublicStores.changeUserInfo(res.data.UserInfo);
      // 设置axios请求头携带
      instance.defaults.headers["AccessToken"] = res.data.AccessToken;
      instance.defaults.headers["RefreshToken"] = res.data.RefreshToken;
      if (savePasswordChecked) {
        savePassword(true);
      } else {
        savePassword(false);
      }
      // 链接websocket
      PublicStores.changeWebSocketService();
      PublicStores.WebSocketService.connect()
      // websocketService.connect();
      // websocketService.addListener(handleMessage);
      router.push("/home");
      ElMessage({
        message: res.message,
        type: "success",
      });
      return;
    }
    ElMessage({
      message: t("Login failure"),
      type: "error",
    });
  });
}

// 注册表单
const registerFormRef = ref(null);
const registerForm = reactive({
  accountType: "0",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
  username: "",
  clinicName: "",
  address: "",
  verificationCode: "",
  registerCode: "",
});
const verifyEmail = (rule, value, callback) => {
  if (value == "") {
    callback(new Error(t("Please enter your email address")));
  } else {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      callback(new Error(t("Please input a valid email address")));
    }
    callback();
  }
};
const verifyPass = (rule, value, callback) => {
  if (value === "") {
    callback(new Error(t("Please enter password")));
  } else {
    callback();
  }
};
const verifyConfirmPass = (rule, value, callback) => {
  if (value === "") {
    callback(new Error(t("Please enter your password here")));
  } else if (value !== registerForm.password) {
    callback(new Error(t("The two passwords are inconsistent")));
  } else {
    callback();
  }
};
const verifyUsername = (rule, value, callback) => {
  if (value == "") {
    callback(new Error(t("Please enter your username")));
  } else {
    callback();
  }
};
const verifyClinicName = (rule, value, callback) => {
  if (value == "") {
    callback(new Error(t("Please enter the clinic name")));
  } else {
    callback();
  }
};
let verificationCodeShow = ref(true);
const verifyCode = async (rule, value, callback) => {
  if (value == "") {
    callback(new Error(t("Please enter the verification code")));
  } else {
    let codedata = {
      ID: VerifyPicture.ID,
      Answer: registerForm.verificationCode,
    };
    let result = await postRequest("/dental/base/verifyCaptcha", codedata);
    if (result.code != 200) {
      getVerifyCode();
      registerForm.verificationCode = "";
      callback(new Error(t("Invalid Code")));
      return;
    }
    verificationCodeShow.value = false;
    callback();
  }
};
const verifyregisterCode = (rule, value, callback) => {
  if (value == "") {
    callback(new Error(t("Please enter the registration code")));
  } else {
    callback();
  }
};
// 注册表规则校验
const rules = reactive({
  email: [{ validator: verifyEmail, trigger: "blur" }],
  password: [{ validator: verifyPass, trigger: "blur" }],
  confirmPassword: [{ validator: verifyConfirmPass, trigger: "blur" }],
  username: [{ validator: verifyUsername, trigger: "blur" }],
  clinicName: [{ validator: verifyClinicName, trigger: "blur" }],
  verificationCode: [{ validator: verifyCode, trigger: "blur" }],
  registerCode: [{ validator: verifyregisterCode, trigger: "blur" }],
});
// 获取时区
// const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
// 获取注册码
let registerCodeState = ref(0);
let Countdown = ref(60);
let timer = null;
function getRegisterCode() {
  registerFormRef.value.validateField("email", async (valid) => {
    if (valid) {
      let res = await postRequest("/dental/base/sendActivationEmail", {
        CaptchaID: VerifyPicture.ID,
        Email: registerForm.email,
      });
      if (res.code != 200) {
        ElMessage({
          message: res.message,
          type: "error",
        });
        registerCodeState.value = 2; // 倒计时结束，显示“重发注册码”
      }
      if (registerCodeState.value === 0 || registerCodeState.value === 2) {
        registerCodeState.value = 1; // 开始倒计时
        Countdown.value = 60;

        timer = setInterval(() => {
          if (Countdown.value > 0) {
            Countdown.value--;
          } else {
            clearInterval(timer);
            registerCodeState.value = 2; // 倒计时结束，显示“重发注册码”
          }
        }, 1000);
      }
    }
  });
}
// 注册按钮逻辑处理
async function register() {
  registerFormRef.value.validate(async (valid) => {
    if (!valid) {
      return;
    }
    let result = await postRequest("/dental/base/validateActivationEmail", {
      EmailActivationCode: registerForm.registerCode,
      Email: registerForm.email,
    });
    if (result.code != 200) {
      ElMessage({
        message: result.message,
        type: "error",
      });
    }
    let data = {
      UserType: registerForm.accountType,
      Email: registerForm.email,
      Phone: registerForm.phone,
      Password: registerForm.password,
      NickName: registerForm.username,
      // TimeZone: userTimeZone,
      EmailActivationCode: registerForm.registerCode,
      Addr: registerForm.address,
      AddrName: registerForm.clinicName,
    };
    postRequest("/dental/base/register", data).then((res) => {
      if (res.code == 200) {
        ElMessage({
          message: res.message,
          type: "success",
        });
        resetRegisterForm();
        return;
      }
      ElMessage({
        message: res.message,
        type: "error",
      });
      getVerifyCode();
      registerForm.verificationCode = "";
    });
  });
}
function resetRegisterForm() {
  registerForm.accountType = "0";
  registerForm.email = "";
  registerForm.Phone = "";
  registerForm.password = "";
  registerForm.confirmPassword = "";
  registerForm.username = "";
  registerForm.clinicName = "";
  registerForm.address = "";
  registerForm.verificationCode = "";
  registerForm.registerCode = "";
}

// 忘记密码的dialog框
const verifyForgotPasswordConfirmPass = (rule, value, callback) => {
  if (value === "") {
    callback(new Error(t("Please enter your password here")));
  } else if (value !== ForgotPasswordForm.password) {
    callback(new Error(t("The two passwords are inconsistent")));
  } else {
    callback();
  }
};
const ForgotPasswordRules = reactive({
  email: [{ validator: verifyEmail, trigger: "blur" }],
  registerCode: [{ validator: verifyregisterCode, trigger: "blur" }],
  password: [{ validator: verifyPass, trigger: "blur" }],
  confirmPassword: [
    { validator: verifyForgotPasswordConfirmPass, trigger: "blur" },
  ],
});
let ForgotPasswordDialogShow = ref(false);
let ForgotPasswordCodeState = ref(0);
let ForgotPasswordRef = ref(null);
let ForgotPasswordForm = reactive({
  email: "",
  registerCode: "",
  password: "",
  confirmPassword: "",
});
function getForgotPasswordCode() {
  ForgotPasswordRef.value.validateField("email", async (valid) => {
    if (valid) {
      let res = await postRequest("/dental/base/sendActivationEmail", {
        Email: ForgotPasswordForm.email,
      });
      if (res.code != 200) {
        ElMessage({
          message: res.message,
          type: "error",
        });
        ForgotPasswordCodeState.value = 2; // 倒计时结束，显示“重发注册码”
      }
      if (
        ForgotPasswordCodeState.value === 0 ||
        ForgotPasswordCodeState.value === 2
      ) {
        ForgotPasswordCodeState.value = 1; // 开始倒计时
        Countdown.value = 60;

        timer = setInterval(() => {
          if (Countdown.value > 0) {
            Countdown.value--;
          } else {
            clearInterval(timer);
            ForgotPasswordCodeState.value = 2; // 倒计时结束，显示“重发注册码”
          }
        }, 1000);
      }
    } else {
      console.log("用户名验证未通过");
      return;
    }
  });
}
function ForgotPasswordSubmit() {
  ForgotPasswordRef.value.validate(async (valid) => {
    if (valid) {
      let data = {
        EmailActivationCode: ForgotPasswordForm.registerCode,
        Email: ForgotPasswordForm.email,
        Password: ForgotPasswordForm.password,
      };
      let res = await postRequest("/dental/base/resetPassword", data);
      if (res.code != 200) {
        ElMessage.error(res.message);
        return;
      }
      ElMessage.success(res.message);
      ForgotPasswordDialogShow.value = false;
    }
  });
}
</script>
<style lang="scss">
.accountTypeSelect {
  top: 44px !important;
  left: 0 !important;
  .el-popper__arrow {
    display: none;
  }
  .el-select-dropdown__list {
    padding-top: 0;
    padding-bottom: 0;
  }
  .el-select-dropdown__item {
    height: 40px;
    line-height: 40px;
  }
  .is-selected {
    background: #d9e7ba;
    color: #494c4f;
    font-family: Source Han Sans CN;
    font-size: 16px;
    font-weight: 400;
  }
}
// 忘记密码dialog
.ForgotPasswordDialog {
  width: 640px;
  padding: 0;
  border-radius: 8px;
  border: 1px solid #ccd0d5;
  box-shadow: 4px 4px 16px 0px #13141540;
  .el-dialog__header {
    margin-right: 0px;
    height: 64px;
    padding: 0px;
    border-bottom: 1px solid #ccd0d5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    .el-dialog__title {
      margin-left: 72px;
      font-size: 20px;
      font-weight: 700;
    }
    .el-dialog__headerbtn {
      width: 40px;
      height: 40px;
      position: static !important; /* 强制取消绝对定位 */
      margin-top: 12px;
      margin-right: 40px;
      color: #000;
      background: url("/public/image/home/accountClose.png");
      background-size: cover;
      i {
        display: none; // 在这里隐藏弹窗默认的关闭图标
      }
    }
  }
  .ForgotPasswordContent {
    width: 100%;
    padding: 40px 64px 0;
    box-sizing: border-box;
    .el-form-item {
      margin-bottom: 20px;
    }
  }
  .el-dialog__footer {
    height: 136px;
    .ForgotPasswordBtn {
      width: 200px;
      height: 48px;
    }
  }
}
.ForgotPasswordForm {
  .el-form-item__label {
    width: 108px;
    height: 40px;
    line-height: 40px;
  }
  .registerCodeInput {
    width: 300px;
  }
  .ForgotPasswordCode {
    width: 100px;
    cursor: pointer;
  }
  .el-input {
    height: 40px;
  }
}
</style>
<style scoped lang="scss">
.login {
  width: 800px;
  height: 100%;
  padding: 80px 196px;
  box-sizing: border-box;
  background: #f6f8fa;
  float: right;
  .title {
    width: 100%;
    height: 100px;
    background: #f6f8fa;
    font-size: 24px;
    font-weight: 500;
    line-height: 38.4px;
    letter-spacing: 0.05em;
    text-align: left;
    .el-text {
      font-size: 36px;
      font-weight: 700;
      line-height: 57.6px;
      letter-spacing: 0.05em;
      text-align: left;
    }
  }
  :deep(.login-tabs) {
    margin-top: 20px;
  }
  :deep(.el-tabs__header) {
    .el-tabs__nav {
      height: 56px;
    }
    .el-tabs__item {
      height: 56px;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      // padding: 0 36px;
    }
    .el-tabs__active-bar {
      height: 4px;
    }
  }
  .loginForm {
    .el-form-item {
      margin-bottom: 40px;
      :deep(.el-checkbox__inner) {
        //大小
        width: 24px;
        height: 24px;
        border-radius: 2px;
      }
      :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
        //对钩的位置
        top: 4px;
        left: 9px;
        height: 9px;
        width: 4px;
        border-width: 2px;
      }
      .userIcon,
      .lockIcon {
        width: 18px;
        height: 18px;
      }
      .eyeOff,
      .eyeOn {
        width: 32px;
        height: 32px;
      }
    }
    .el-form-item:nth-child(3) {
      margin-bottom: 67px;
    }
    .el-form-item:nth-child(1) {
      margin-top: 45px;
    }
    .el-input {
      height: 56px;
      :deep(.el-input__prefix-inner) {
        padding-right: 10px;
        padding-left: 7px;
      }
    }
    .loginBtn {
      width: 100%;
      height: 64px;
    }
  }
  .registerForm {
    .el-form-item {
      margin-bottom: 20px;
      :deep(.el-form-item__label) {
        width: 108px;
        font-weight: 1000;
        color: #494c4f;
        height: 40px;
        line-height: 40px;
      }
      .eyeOff,
      .eyeOn {
        width: 32px;
        height: 32px;
      }
      .verificationCodeInput {
        width: 180px;
      }
      .verificationCodeImage {
        width: 80px;
        height: 40px;
        margin-left: 8px;
      }
      .verificationCodeRefresh {
        width: 32px;
        height: 32px;
      }
      .registerCodeInput {
        width: 180px;
      }
      .getRegisterCode {
        width: 120px;
        height: 100%;
        cursor: pointer;
      }
      .el-input {
        height: 40px;
      }
      :deep(.el-select__wrapper) {
        height: 40px !important;
      }
    }
    .el-form-item:nth-child(1) {
      margin-top: 17px;
    }
    .registerBtn {
      width: 100%;
      height: 64px;
    }
  }
}
</style>
