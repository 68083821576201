/*
 * @Description: 
 * @Autor: fzw
 * @Date: 2024-07-17 18:30:23
 * @LastEditTime: 2024-08-29 11:19:28
 */
import { createWebHistory, createRouter } from 'vue-router'
import { PublicStore } from "@/tools/pinia/store";
import Home from "@/view/Home.vue"
import login from "@/view/login.vue"
const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: login },
  { path: '/home', component: Home },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  let publicData = PublicStore()
  if (publicData.AccessToken=='' && to.path !== '/login' && to.path !== '/') {
    next('/login');
  } else {
    next();
  }
});
export default router