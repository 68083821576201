<template>
  <div class="InviteListContent">
    <!-- 添加合作伙伴btn -->
    <div class="AddPartnerBox">
      <div class="AddPartnerBtn" v-limit="2" @click="handleOpenDialog">
        <img style="vertical-align: middle" src="/image/home/AddPartner.png" />
        <span>{{ t("Add Partner") }}</span>
      </div>
    </div>
    <!-- 合作伙伴状态 -->
    <div class="PartnerListBox">
      <div
        v-for="(item, index) in InviteData"
        :style="index % 2 != 1 ? { background: '#F9FBFD' } : {}"
        :key="item.ID"
      >
        <!-- 等待接受 -->
        <div
          class="flexSpaceBetween PartnerCard"
          v-if="PublicStores.UserInfo.UserType == 0 && item.Status == 0"
        >
          <!-- 备注 邮箱  用户名-->
          <div class="leftBox">
            <p>{{ item.OtherAddrName }}</p>
            <p>{{ t("Email") }}:&nbsp;&nbsp;{{ item.OtherEmail }}</p>
            <p>{{ t("User Name") }}:&nbsp;&nbsp;{{ item.OtherNickName }}</p>
          </div>
          <!-- 邀请日期状态 -->
          <div class="centerBox">
            <p>
              {{ t("Invitation Date") }}：<span>{{ item.CreatedAt }}</span>
            </p>
            <p>
              <img src="/image/home/wait.png" /><span>{{ item.state }}</span>
              <el-button
                v-limit="6"
                link
                type="primary"
                v-debounce:click="{
                  handler: () => emailResend(item),
                  delay: 300,
                }"
                >{{ t("Resend Invitation") }}</el-button
              >
            </p>
          </div>
          <el-button
            v-limit="3"
            class="deleteBtn"
            type="default"
            @click="delPartner(item.ID)"
            ><img src="/image/home/delete.png" /> {{ t("Delete") }}</el-button
          >
        </div>
        <!-- 医生添加等待接受状态下  技工所接受与拒绝 -->
        <div
          class="flexSpaceBetween PartnerCard"
          v-if="PublicStores.UserInfo.UserType == 1 && item.Status == 0"
        >
          <!-- 备注 邮箱 -->
          <div class="leftBox">
            <p>{{ item.OtherAddrName }}</p>
            <p>{{ t("Email") }}:&nbsp;&nbsp;{{ item.OtherEmail }}</p>
            <p>{{ t("User Name") }}:&nbsp;&nbsp;{{ item.OtherNickName }}</p>
          </div>
          <!-- 邀请日期状态 -->
          <div class="centerBox">
            <p>
              {{ t("Invitation Date") }}：<span>{{ item.CreatedAt }}</span>
            </p>
            <p><img src="/image/home/wait.png" />{{ item.state }}</p>
          </div>

          <div class="rightBox">
            <el-button
              class="acceptBtn"
              type="primary"
              v-debounce:click="{
                handler: () => ProcessInvitate(item.ID, '1'),
                delay: 300,
              }"
            >
              <img src="/image/home/accept.png" />
              {{ t("Accept") }}</el-button
            >
            <el-button
              class="RejectBtn"
              type="default"
              v-debounce:click="{
                handler: () => ProcessInvitate(item.ID, '2'),
                delay: 300,
              }"
            >
              <img src="/image/home/reject.png" />
              {{ t("Reject") }}
            </el-button>
          </div>
        </div>
        <!-- 已接受 -->
        <div class="flexSpaceBetween PartnerCard" v-if="item.Status == 1">
          <!-- 诊所名 邮箱 用户名-->
          <div class="leftBox">
            <p>
              {{ item.OtherAddrName }}
            </p>
            <p>{{ t("Email") }}:&nbsp;&nbsp;{{ item.OtherEmail }}</p>
            <p>{{ t("User Name") }}:&nbsp;&nbsp;{{ item.OtherNickName }}</p>
          </div>
          <!-- 邀请日期状态 -->
          <div class="centerBox">
            <p>
              {{ t("Partnership Established Date") }}：<span>{{
                item.ConfirmedAt
              }}</span>
            </p>
            <p>
              {{ t("Case Submitted") }}:&nbsp;&nbsp;&nbsp;{{ item.OrderCount }}
            </p>
          </div>
          <el-button
            v-limit="3"
            type="default"
            class="deleteBtn"
            @click="delPartner(item.ID)"
            ><img style="margin-right: 12px" src="/image/home/delete.png" />
            {{ t("Delete") }}</el-button
          >
          <div
            style="opacity: 0"
            class="deleteBtn"
            v-show="PublicStores.UserInfo.UserType == 1"
          ></div>
        </div>
        <!-- 已拒绝 -->
        <div class="flexSpaceBetween PartnerCard" v-if="item.Status == 2">
          <!-- 诊所名 邮箱 用户名 -->
          <div class="leftBox">
            <p>
              {{ item.OtherAddrName }}
            </p>
            <p>{{ t("Email") }}:&nbsp;&nbsp;{{ item.OtherEmail }}</p>
            <p>{{ t("User Name") }}:&nbsp;&nbsp;{{ item.OtherNickName }}</p>
          </div>
          <!-- 邀请日期状态 -->
          <div class="centerBox">
            <p>
              {{ t("Invitation Date") }}：<span>{{ item.ConfirmedAt }}</span>
            </p>
            <p>
              <img src="/image/home/redReject.png" />
              <span>{{ item.state }}</span>
              <el-button
                v-limit="6"
                link
                type="primary"
                v-debounce:click="{
                  handler: () => emailResend(item),
                  delay: 300,
                }"
                >{{ t("Resend Invitation") }}</el-button
              >
            </p>
          </div>
          <el-button
            v-limit="3"
            type="default"
            class="deleteBtn"
            @click="delPartner(item.ID)"
            ><img style="margin-right: 12px" src="/image/home/delete.png" />
            {{ t("Delete") }}</el-button
          >
          <div
            style="opacity: 0"
            class="deleteBtn"
            v-show="PublicStores.UserInfo.UserType == 1"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script setup>
import { PublicStore } from "@/tools/pinia/store.js";
const { t } = useI18n();
let PublicStores = PublicStore();
const props = defineProps({
    InviteData: {
    type: Array,
    default: [],
  },
});
let emit = defineEmits(["handleOpenDialog","openInviteList"]);
function handleOpenDialog(){
    emit("handleOpenDialog")
}
// 邮件重新发送
async function emailResend(item) {
  let Email = item.OtherEmail != "" ? item.OtherEmail : item.InviteEmail;
  if (item.OtherEmail == "") {
    let result = await postRequest("/dental/user/delPartner", { ID: item.ID });
    if (result.code != 200) {
      ElMessage({
        message: res.message,
        type: "error",
      });
      return;
    }
  }

  postRequest("/dental/user/addPartner", { Email: Email }).then((res) => {
    if (res.code == 200) {
      ElMessage({
        message: res.message,
        type: "success",
      });
      emit("openInviteList")
      innerVisible.value = false;
      return;
    }
    ElMessage({
      message: res.message,
      type: "error",
    });
  });
}
function delPartner(value) {
  ElMessageBox({
    title: t("Prompt"),
    message: h("p", null, [
      h("span", null, t("Whether to cancel the partnership?")),
    ]),
    showCancelButton: true,
    confirmButtonText: t("Yes"),
    cancelButtonText: t("No"),
    customClass: "confirmMessageBox", // 添加自定义类名
    confirmButtonClass: "confirm-button-with-icon", // 自定义确认按钮类
    cancelButtonClass: "cancel-button-with-icon", // 自定义取消按钮类
    modal: false, // 去除遮罩层
    draggable: true,
    overflow: true,
  }).then(() => {
    let data = {
      ID: value,
    };
    postRequest("/dental/user/delPartner", data).then((res) => {
      if (res.code == 200) {
        ElMessage({
          message: res.message,
          type: "success",
        });
        emit("openInviteList")
        return;
      }
      ElMessage({
        message: res.message,
        type: "error",
      });
    });
  });
}
async function ProcessInvitate(ID, Status) {
  let data = {
    ID: ID,
    Status: Status,
  };
  let res = await postRequest("/dental/user/handleInvitation", data);
  if (res.code == 200) {
    emit("openInviteList")
    ElMessage.success(res.message);
    reutrn
  }
  ElMessage.error(res.message);
}
</script>
    
<style scoped lang='scss'>
  .InviteListContent {
    padding: 0 72px 88px 72px;
    .AddPartnerBox {
    height: 56px;
    color: #6f9c29;
    font-size: 20px;
    .AddPartnerBtn {
      width: 168px;
      height: 40px;
      float: right;
      margin-top: 12px;
      cursor: pointer;
      img {
        width: 40px;
        height: 40px;
      }
    }
  }
  .PartnerListBox {
    height: 568px;
    overflow: auto;
    border: 1px solid #ccd0d5;
    .PartnerCard {
      height: 120px;
      border-bottom: 1px solid #ccd0d5;
      overflow: hidden;
      box-sizing: border-box;
      .leftBox {
        margin-left: 24px;
        width: 366px;
        p:nth-of-type(1) {
          height: 27px;
          font-size: 18px;
          font-weight: 600;
          color: #343638;
          line-height: 27px;
          margin: 0 0 11px 0;
        }
        p:nth-of-type(2) {
          margin: 0 0 0 0;
        }
        p:nth-of-type(3) {
          margin: 0 0 0 0;
        }
      }
      .centerBox {
        width: 350px;
        p:nth-of-type(1) {
          line-height: 24px;
          span {
            color: #343638;
            font-weight: 600;
          }
        }
        p:nth-of-type(2) {
          line-height: 24px;
        }
        img {
          width: 24px;
          height: 24px;
        }
        .el-button {
          width: 136px;
          margin-bottom: 6px;
        }
      }
      .rightBox {
        width: 88px;
        margin-right: 40px;
        .acceptBtn {
          width: 88px;
          height: 32px;
          img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
        }
        .RejectBtn {
          width: 88px;
          height: 32px;
          margin: 8px 0 0 0;
          background: #f3f4f7;
          img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
        }
      }
      .deleteBtn {
        width: 112px;
        height: 40px;
        margin: 16px 40px 0 0;
        background: #f3f4f7;
        img {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }
    }
  }
  }
</style>
